import React from "react"
import styled from "styled-components"

const Container = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
    margin: 20px;
`


const ContactUsContent = () => {
    return (
        <Container>
            <div>
                {/* Begin Mailchimp Signup Form */}
                <link href="//cdn-images.mailchimp.com/embedcode/classic-10_7.css" rel="stylesheet" type="text/css" />
                <style type="text/css" dangerouslySetInnerHTML={{__html: "\n\t.title{line-height: 30px;} #mc-embedded-subscribe.button{background:#589bd4; margin-top:20px;} #mc_embed_signup_scroll{font:17px Karla;} #mc_embed_signup{background:#eff9fe; clear:left; font:14px Karla,Arial,sans-serif; border:40px solid #eff9fe; border-radius:25px; margin:50px;}\n\t/* Add your own Mailchimp form style overrides in your site stylesheet or in this style block.\n    \n\t   We recommend moving this block and the preceding CSS link to the HEAD of your HTML file. */\n" }} />
                <div id="mc_embed_signup">
                <form action='https://bulksaversinc.us6.list-manage.com/subscribe/post?u=ce2c14f3d66b9c10b4ce9a429&id=be20d6484b' method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank" noValidate>
                    <div id="mc_embed_signup_scroll">
                    <h2 className="title">Sign up to receive news, updates, and offers!</h2>
                    <div className="indicates-required"><span className="asterisk">*</span> indicates required</div>
                    <div className="mc-field-group">
                        <label htmlFor="mce-EMAIL">Email Address  <span className="asterisk">*</span>
                        </label>
                        <input type="email" defaultValue="" name="EMAIL" className="required email" id="mce-EMAIL" />
                    </div>
                    <div className="mc-field-group">
                        <label htmlFor="mce-FNAME">First Name  <span className="asterisk">*</span>
                        </label>
                        <input type="text" defaultValue="" name="FNAME" className="required" id="mce-FNAME" />
                    </div>
                    <div className="mc-field-group">
                        <label htmlFor="mce-LNAME">Last Name  <span className="asterisk">*</span>
                        </label>
                        <input type="text" defaultValue="" name="LNAME" className="required" id="mce-LNAME" />
                    </div>
                    <div id="mce-responses" className="clear">
                        <div className="response" id="mce-error-response" style={{display: 'none'}} />
                        <div className="response" id="mce-success-response" style={{display: 'none'}} />
                    </div>    {/* real people should not fill this in and expect good things - do not remove this or risk form bot signups*/}
                    <div style={{position: 'absolute', left: '-5000px'}} aria-hidden="true"><input type="text" name="b_ce2c14f3d66b9c10b4ce9a429_be20d6484b" tabIndex={-1} defaultValue /></div>
                    <div className="clear"><input type="submit" defaultValue="Subscribe" name="subscribe" id="mc-embedded-subscribe" className="button" /></div>
                    </div>
                </form>
                </div>
                {/*End Mailchimp Signup Form*/}
            </div>
        </Container>
      );
    }

export default ContactUsContent