import React from "react"
import Layout from "../components/layout"
import PageBanner from "../components/PageBanner"
import ContactUsContent from "../components/ContactUs/ContactUsContent"
import { Helmet } from "react-helmet"


const ContactUsPage = () => (
  <>
    <Helmet>
      <title>Contact Us | Maxie Paper</title>
      <meta charSet="utf-8" />
      <meta name="description" content=""/>
      <meta name="keywords" content="Maxie, Maxie Needs, Toilet Paper"/>
    </Helmet>
    <Layout>
      <PageBanner title="Contact Us" description="we’d love to hear from you"/>
      <ContactUsContent/>
    </Layout>
  </>
)

export default ContactUsPage
